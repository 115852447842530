@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

@layer base {
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-light.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-light-italic.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-regular.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-regular-italic.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-medium.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-medium-italic.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-bold.woff2') format('woff2');
    }
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto/roboto-bold-italic.woff2') format('woff2');
    }
    @font-face {
        font-family: 'RobotoMono';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto-mono/roboto-mono.woff2') format('woff2');
    }
    @font-face {
        font-family: 'RobotoMono';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto-mono/roboto-mono.woff2') format('woff2');
    }
    @font-face {
        font-family: 'RobotoMono';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('https://cdn.lsoft.online/file/fonts/roboto-mono/roboto-mono.woff2') format('woff2');
    }
}

@layer components {

    .bg-alert-info-filled {
        @apply bg-blue-500
    }

    .bg-alert-info-transparent {
        @apply bg-blue-500/20
    }

    .bg-alert-info-transparent-hover {
        @apply bg-blue-500/30
    }

    .text-alert-info-filled {
        @apply text-white
    }

    .text-alert-info-transparent {
        @apply text-blue-800
    }

    .text-alert-info {
        @apply text-blue-500
    }

    .border-alert-info {
        @apply border-blue-500
    }



    .bg-alert-error-filled {
        @apply bg-red-500
    }

    .bg-alert-error-transparent {
        @apply bg-red-500/20
    }

    .bg-alert-error-transparent-hover {
        @apply bg-red-500/30
    }

    .text-alert-error-filled {
        @apply text-white
    }

    .text-alert-error-transparent {
        @apply text-red-800
    }

    .text-alert-error {
        @apply text-red-500
    }

    .border-alert-error {
        @apply border-red-500
    }

    .bg-alert-warning-filled {
        @apply bg-orange-300
    }

    .bg-alert-warning-transparent {
        @apply bg-orange-300/30
    }

    .bg-alert-warning-transparent-hover {
        @apply bg-orange-300/50
    }

    .text-alert-warning-filled {
        @apply text-black
    }

    .text-alert-warning-transparent {
        @apply text-orange-600
    }

    .text-alert-warning {
        @apply text-orange-500
    }

    .border-alert-warning {
        @apply border-orange-300
    }

    .bg-alert-primary-filled {
        @apply bg-primary-500
    }

    .bg-alert-primary-transparent {
        @apply bg-primary-500/20
    }

    .bg-alert-primary-transparent-hover {
        @apply bg-primary-500/40
    }

    .text-alert-primary-filled {
        @apply text-white
    }

    .text-alert-primary-transparent {
        @apply text-primary-800
    }

    .text-alert-primary {
        @apply text-primary-500
    }

    .border-alert-primary {
        @apply border-primary-500
    }

    .bg-alert-secondary-filled {
        @apply bg-secondary-500
    }

    .bg-alert-secondary-transparent {
        @apply bg-secondary-500/20
    }

    .bg-alert-secondary-transparent-hover {
        @apply bg-secondary-500/40
    }

    .text-alert-secondary-filled {
        @apply text-white
    }

    .text-alert-secondary-transparent {
        @apply text-secondary-800
    }

    .text-alert-secondary {
        @apply text-secondary-500
    }

    .border-alert-secondary {
        @apply border-secondary-500
    }
}

@layer components {
    .scrollbar {
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            scrollbar-gutter: stable;
            overflow-y: auto;
            z-index: 1;
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            @apply bg-gray-300;
        }
    }

    .toast-base {
        @apply bottom-2 left-2 fixed w-[800px];
    }

    .z-appbar {
        @apply z-[1100]
    }

    .z-page-title {
        @apply z-[20]
    }

    .z-sheet-backdrop {
        @apply z-[1250]
    }

    .z-sheet {
        @apply z-[1300]
    }

    .z-input-content {
        @apply z-[2450]
    }

    .z-card-icon-button {
        @apply z-[10];
    }

    .shadow-custom {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.4), 0 2px 4px -2px rgb(0 0 0 / 0.4);
    }

    .bred {
        @apply border border-red-500;
    }

    .bblue {
        @apply border border-blue-500;
    }

}
